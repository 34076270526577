// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_navLink__s4MQC {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #596780;
  padding-block: 14px;
  padding-inline: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
  gap: 14px;
  border-radius: 10px;
}

.styles_navLink__s4MQC:hover {
  background-color: #EBF2FF;
}

.styles_activeNavLink__8iQ0z {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  padding-block: 14px;
  padding-inline: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--primary);
  font-family: var(--font-secondary);
  gap: 14px;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.styles_activeNavLink__8iQ0z .styles_navIcon__mIzXq path {
  transition: stroke 0.3s;
  fill: #fff !important;
}
.styles_activeNavLink__8iQ0z .styles_altNavIcon__d7IsN path {
  transition: stroke 0.3s;
  stroke: #fff !important;
}`, "",{"version":3,"sources":["webpack://./src/layout/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;EACnB,oBAAoB;EACpB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,6CAA6C;EAC7C,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,mBAAmB;EACnB,oBAAoB;EACpB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,gCAAgC;EAChC,kCAAkC;EAClC,SAAS;EACT,mBAAmB;EACnB,6CAA6C;AAC/C;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;AACvB;AACA;EACE,uBAAuB;EACvB,uBAAuB;AACzB","sourcesContent":[".navLink {\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 22px;\n  color: #596780;\n  padding-block: 14px;\n  padding-inline: 16px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  transition: background-color 0.3s, color 0.3s;\n  gap: 14px;\n  border-radius: 10px;\n}\n\n.navLink:hover {\n  background-color: #EBF2FF;\n}\n\n.activeNavLink {\n  font-weight: 600;\n  font-size: 18px;\n  color: #ffffff;\n  padding-block: 14px;\n  padding-inline: 16px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  background-color: var(--primary);\n  font-family: var(--font-secondary);\n  gap: 14px;\n  border-radius: 10px;\n  transition: background-color 0.3s, color 0.3s;\n}\n\n.activeNavLink .navIcon path {\n  transition: stroke 0.3s;\n  fill: #fff !important;\n}\n.activeNavLink .altNavIcon path {\n  transition: stroke 0.3s;\n  stroke: #fff !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navLink": `styles_navLink__s4MQC`,
	"activeNavLink": `styles_activeNavLink__8iQ0z`,
	"navIcon": `styles_navIcon__mIzXq`,
	"altNavIcon": `styles_altNavIcon__d7IsN`
};
export default ___CSS_LOADER_EXPORT___;
