// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/backgrounds/auth.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-bg {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}), var(--blue, linear-gradient(180deg, #0575E6 0%, #02298A 84.79%, #021B79 100%));
  background-repeat: no-repeat;
  background-size: auto;
  background-position: -207px 65vh, center;
}`, "",{"version":3,"sources":["webpack://./src/styles/backgrounds.css"],"names":[],"mappings":"AAAA;EACE,yIAAqI;EACrI,4BAA4B;EAC5B,qBAAqB;EACrB,wCAAwC;AAC1C","sourcesContent":[".auth-bg {\n  background-image: url(../assets/backgrounds/auth.svg), var(--blue, linear-gradient(180deg, #0575E6 0%, #02298A 84.79%, #021B79 100%));\n  background-repeat: no-repeat;\n  background-size: auto;\n  background-position: -207px 65vh, center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
