export const LessonsIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
  >
    <path
      d='M20.5 6.35608H12.5L10.5 4.35608H4.5C3.4 4.35608 2.51 5.25608 2.51 6.35608L2.5 18.3561C2.5 19.4561 3.4 20.3561 4.5 20.3561H21.27C21.95 20.3561 22.5 19.7961 22.5 19.1261V8.35608C22.5 7.25608 21.6 6.35608 20.5 6.35608ZM20.5 18.3561H4.5V6.35608H9.67L11.67 8.35608H20.5V18.3561ZM18.5 12.3561H6.5V10.3561H18.5V12.3561ZM14.5 16.3561H6.5V14.3561H14.5V16.3561Z'
      fill='#1D4ED8'
    />
  </svg>
);

export const TimeIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
  >
    <path
      d='M12.5 2.35608C7 2.35608 2.5 6.85608 2.5 12.3561C2.5 17.8561 7 22.3561 12.5 22.3561C18 22.3561 22.5 17.8561 22.5 12.3561C22.5 6.85608 18 2.35608 12.5 2.35608ZM12.5 20.3561C8.09 20.3561 4.5 16.7661 4.5 12.3561C4.5 7.94608 8.09 4.35608 12.5 4.35608C16.91 4.35608 20.5 7.94608 20.5 12.3561C20.5 16.7661 16.91 20.3561 12.5 20.3561ZM13 7.35608H11.5V13.3561L16.7 16.5561L17.5 15.2561L13 12.5561V7.35608Z'
      fill='#F59E0B'
    />
  </svg>
);
