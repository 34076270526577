// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_modalTitleContainer__lXwKV {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-wrap: nowrap;
	margin-bottom: 38px;
	padding-bottom: 10px;
	border-bottom: 2px solid #F3F6FA;
}

.style_modalTitleContainer__lXwKV>h1 {
	margin: 0;
	font-size: 1.3rem;
	font-weight: 600;
}

.style_modalTitleContainer__lXwKV>button {
	font-size: 20px;
	border: none;
	transition: color 0.5s;
}

.style_modalTitleContainer__lXwKV>button:hover {
	cursor: pointer;
	color: red;
}

@media (max-width: 800px) {
	.style_modalTitleContainer__lXwKV>h1 {
		font-size: 1.3rem;
	}
}`, "",{"version":3,"sources":["webpack://./src/common/CustomModal/style.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,mBAAmB;CACnB,8BAA8B;CAC9B,iBAAiB;CACjB,mBAAmB;CACnB,oBAAoB;CACpB,gCAAgC;AACjC;;AAEA;CACC,SAAS;CACT,iBAAiB;CACjB,gBAAgB;AACjB;;AAEA;CACC,eAAe;CACf,YAAY;CACZ,sBAAsB;AACvB;;AAEA;CACC,eAAe;CACf,UAAU;AACX;;AAEA;CACC;EACC,iBAAiB;CAClB;AACD","sourcesContent":[".modalTitleContainer {\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: center;\n\tjustify-content: space-between;\n\tflex-wrap: nowrap;\n\tmargin-bottom: 38px;\n\tpadding-bottom: 10px;\n\tborder-bottom: 2px solid #F3F6FA;\n}\n\n.modalTitleContainer>h1 {\n\tmargin: 0;\n\tfont-size: 1.3rem;\n\tfont-weight: 600;\n}\n\n.modalTitleContainer>button {\n\tfont-size: 20px;\n\tborder: none;\n\ttransition: color 0.5s;\n}\n\n.modalTitleContainer>button:hover {\n\tcursor: pointer;\n\tcolor: red;\n}\n\n@media (max-width: 800px) {\n\t.modalTitleContainer>h1 {\n\t\tfont-size: 1.3rem;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTitleContainer": `style_modalTitleContainer__lXwKV`
};
export default ___CSS_LOADER_EXPORT___;
