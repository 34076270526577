export const HomeIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18C12.75 18.41 12.41 18.75 12 18.75Z'
        fill='#596780'
      />
      <path
        d='M17.6 22.56H6.39996C4.57996 22.56 2.91996 21.16 2.61996 19.37L1.28996 11.4C1.06996 10.16 1.67996 8.57001 2.66996 7.78001L9.59996 2.23001C10.94 1.15001 13.05 1.16001 14.4 2.24001L21.33 7.78001C22.31 8.57001 22.91 10.16 22.71 11.4L21.38 19.36C21.08 21.13 19.38 22.56 17.6 22.56ZM11.99 2.93001C11.46 2.93001 10.93 3.09001 10.54 3.40001L3.60996 8.96001C3.04996 9.41001 2.64996 10.45 2.76996 11.16L4.09996 19.12C4.27996 20.17 5.32996 21.06 6.39996 21.06H17.6C18.67 21.06 19.72 20.17 19.9 19.11L21.23 11.15C21.34 10.45 20.94 9.39001 20.39 8.95001L13.46 3.41001C13.06 3.09001 12.52 2.93001 11.99 2.93001Z'
        fill='#596780'
      />
    </svg>
  );
};
export const TopicIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      className={className}
      fill='none'
    >
      <path
        d='M15 12.95H8C7.59 12.95 7.25 12.61 7.25 12.2C7.25 11.79 7.59 11.45 8 11.45H15C15.41 11.45 15.75 11.79 15.75 12.2C15.75 12.61 15.41 12.95 15 12.95Z'
        fill='#596780'
      />
      <path
        d='M12.38 16.95H8C7.59 16.95 7.25 16.61 7.25 16.2C7.25 15.79 7.59 15.45 8 15.45H12.38C12.79 15.45 13.13 15.79 13.13 16.2C13.13 16.61 12.79 16.95 12.38 16.95Z'
        fill='#596780'
      />
      <path
        d='M14 6.75H10C9.04 6.75 7.25 6.75 7.25 4C7.25 1.25 9.04 1.25 10 1.25H14C14.96 1.25 16.75 1.25 16.75 4C16.75 4.96 16.75 6.75 14 6.75ZM10 2.75C9.01 2.75 8.75 2.75 8.75 4C8.75 5.25 9.01 5.25 10 5.25H14C15.25 5.25 15.25 4.99 15.25 4C15.25 2.75 14.99 2.75 14 2.75H10Z'
        fill='#596780'
      />
      <path
        d='M15 22.7501H9C3.38 22.7501 2.25 20.1701 2.25 16.0001V10.0001C2.25 5.44005 3.9 3.49005 7.96 3.28005C8.36 3.26005 8.73 3.57005 8.75 3.99005C8.77 4.41005 8.45 4.75005 8.04 4.77005C5.2 4.93005 3.75 5.78005 3.75 10.0001V16.0001C3.75 19.7001 4.48 21.2501 9 21.2501H15C19.52 21.2501 20.25 19.7001 20.25 16.0001V10.0001C20.25 5.78005 18.8 4.93005 15.96 4.77005C15.55 4.75005 15.23 4.39005 15.25 3.98005C15.27 3.57005 15.63 3.25005 16.04 3.27005C20.1 3.49005 21.75 5.44005 21.75 9.99005V15.9901C21.75 20.1701 20.62 22.7501 15 22.7501Z'
        fill='#596780'
      />
    </svg>
  );
};
export const ClassIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      className={className}
    >
      <path
        d='M12.0099 17C11.1599 17 10.2999 16.78 9.62995 16.35L3.60995 12.42C2.48995 11.69 1.81995 10.46 1.81995 9.12003C1.81995 7.78003 2.48995 6.55003 3.60995 5.82003L9.63995 1.90003C10.9799 1.03003 13.0699 1.03003 14.3999 1.91003L20.3899 5.84003C21.4999 6.57003 22.1699 7.80003 22.1699 9.13003C22.1699 10.46 21.4999 11.69 20.3899 12.42L14.3999 16.35C13.7299 16.79 12.8699 17 12.0099 17ZM12.0099 2.75003C11.4399 2.75003 10.8699 2.88003 10.4599 3.16003L4.43995 7.08003C3.73995 7.54003 3.32995 8.28003 3.32995 9.12003C3.32995 9.96003 3.72995 10.7 4.43995 11.16L10.4599 15.09C11.2899 15.63 12.7499 15.63 13.5799 15.09L19.5699 11.16C20.2699 10.7 20.6699 9.96003 20.6699 9.12003C20.6699 8.28003 20.2699 7.54003 19.5699 7.08003L13.5799 3.15003C13.1599 2.89003 12.5899 2.75003 12.0099 2.75003Z'
        fill='#596780'
      />
      <path
        d='M12.0001 22.7501C11.5601 22.7501 11.1101 22.6901 10.7501 22.5701L7.56006 21.5101C6.05006 21.0101 4.86006 19.3601 4.87006 17.7701L4.88006 13.0801C4.88006 12.6701 5.22006 12.3301 5.63006 12.3301C6.04006 12.3301 6.38006 12.6701 6.38006 13.0801L6.37006 17.7701C6.37006 18.7101 7.15006 19.7901 8.04006 20.0901L11.2301 21.1501C11.6301 21.2801 12.3701 21.2801 12.7701 21.1501L15.9601 20.0901C16.8501 19.7901 17.6301 18.7101 17.6301 17.7801V13.1401C17.6301 12.7301 17.9701 12.3901 18.3801 12.3901C18.7901 12.3901 19.1301 12.7301 19.1301 13.1401V17.7801C19.1301 19.3701 17.9501 21.0101 16.4401 21.5201L13.2501 22.5801C12.8901 22.6901 12.4401 22.7501 12.0001 22.7501Z'
        fill='#596780'
      />
      <path
        d='M21.4 15.75C20.99 15.75 20.65 15.41 20.65 15V9C20.65 8.59 20.99 8.25 21.4 8.25C21.81 8.25 22.15 8.59 22.15 9V15C22.15 15.41 21.81 15.75 21.4 15.75Z'
        fill='#596780'
      />
    </svg>
  );
};
export const UserIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      className={className}
    >
      <path
        d='M12 12.75C8.83 12.75 6.25 10.17 6.25 7C6.25 3.83 8.83 1.25 12 1.25C15.17 1.25 17.75 3.83 17.75 7C17.75 10.17 15.17 12.75 12 12.75ZM12 2.75C9.66 2.75 7.75 4.66 7.75 7C7.75 9.34 9.66 11.25 12 11.25C14.34 11.25 16.25 9.34 16.25 7C16.25 4.66 14.34 2.75 12 2.75Z'
        fill='#596780'
      />
      <path
        d='M20.5901 22.75C20.1801 22.75 19.8401 22.41 19.8401 22C19.8401 18.55 16.3202 15.75 12.0002 15.75C7.68015 15.75 4.16016 18.55 4.16016 22C4.16016 22.41 3.82016 22.75 3.41016 22.75C3.00016 22.75 2.66016 22.41 2.66016 22C2.66016 17.73 6.85015 14.25 12.0002 14.25C17.1502 14.25 21.3401 17.73 21.3401 22C21.3401 22.41 21.0001 22.75 20.5901 22.75Z'
        fill='#596780'
      />
    </svg>
  );
};
export const RoleIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      className={className}
    >
      <path
        d='M16 22.75H7.99998C3.37998 22.75 2.51998 20.6 2.29998 18.51L1.54998 10.5C1.43998 9.45001 1.40998 7.90001 2.44998 6.74001C3.34998 5.74001 4.83998 5.26001 6.99998 5.26001H17C19.17 5.26001 20.66 5.75001 21.55 6.74001C22.59 7.90001 22.56 9.45001 22.45 10.51L21.7 18.5C21.48 20.6 20.62 22.75 16 22.75ZM6.99998 6.75001C5.30998 6.75001 4.14998 7.08001 3.55998 7.74001C3.06998 8.28001 2.90998 9.11001 3.03998 10.35L3.78998 18.36C3.95998 19.94 4.38998 21.25 7.99998 21.25H16C19.6 21.25 20.04 19.94 20.21 18.35L20.96 10.36C21.09 9.11001 20.93 8.28001 20.44 7.74001C19.85 7.08001 18.69 6.75001 17 6.75001H6.99998Z'
        fill='#596780'
      />
      <path
        d='M16 6.75C15.59 6.75 15.25 6.41 15.25 6V5.2C15.25 3.42 15.25 2.75 12.8 2.75H11.2C8.75 2.75 8.75 3.42 8.75 5.2V6C8.75 6.41 8.41 6.75 8 6.75C7.59 6.75 7.25 6.41 7.25 6V5.2C7.25 3.44 7.25 1.25 11.2 1.25H12.8C16.75 1.25 16.75 3.44 16.75 5.2V6C16.75 6.41 16.41 6.75 16 6.75Z'
        fill='#596780'
      />
      <path
        d='M12 16.75C9.25 16.75 9.25 15.05 9.25 14.03V13C9.25 11.59 9.59 11.25 11 11.25H13C14.41 11.25 14.75 11.59 14.75 13V14C14.75 15.04 14.75 16.75 12 16.75ZM10.75 12.75C10.75 12.83 10.75 12.92 10.75 13V14.03C10.75 15.06 10.75 15.25 12 15.25C13.25 15.25 13.25 15.09 13.25 14.02V13C13.25 12.92 13.25 12.83 13.25 12.75C13.17 12.75 13.08 12.75 13 12.75H11C10.92 12.75 10.83 12.75 10.75 12.75Z'
        fill='#596780'
      />
      <path
        d='M14 14.77C13.63 14.77 13.3 14.49 13.26 14.11C13.21 13.7 13.5 13.32 13.91 13.27C16.55 12.94 19.08 11.94 21.21 10.39C21.54 10.14 22.01 10.22 22.26 10.56C22.5 10.89 22.43 11.36 22.09 11.61C19.75 13.31 16.99 14.4 14.09 14.77C14.06 14.77 14.03 14.77 14 14.77Z'
        fill='#596780'
      />
      <path
        d='M9.99999 14.7799C9.96999 14.7799 9.93999 14.7799 9.90999 14.7799C7.16999 14.4699 4.49999 13.4699 2.18999 11.8899C1.84999 11.6599 1.75999 11.1899 1.98999 10.8499C2.21999 10.5099 2.68999 10.4199 3.02999 10.6499C5.13999 12.0899 7.56999 12.9999 10.07 13.2899C10.48 13.3399 10.78 13.7099 10.73 14.1199C10.7 14.4999 10.38 14.7799 9.99999 14.7799Z'
        fill='#596780'
      />
    </svg>
  );
};

export const SettingIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      className={className}
    >
      <path
        d='M12 22.63C11.33 22.63 10.65 22.48 10.12 22.17L4.62001 19C2.38001 17.49 2.24001 17.26 2.24001 14.89V9.11005C2.24001 6.74005 2.37001 6.51005 4.57001 5.02005L10.11 1.82005C11.16 1.21005 12.81 1.21005 13.86 1.82005L19.38 5.00005C21.62 6.51005 21.76 6.74005 21.76 9.11005V14.88C21.76 17.25 21.63 17.48 19.43 18.97L13.89 22.17C13.35 22.48 12.67 22.63 12 22.63ZM12 2.87005C11.58 2.87005 11.17 2.95005 10.88 3.12005L5.38001 6.30005C3.75001 7.40005 3.75001 7.40005 3.75001 9.11005V14.88C3.75001 16.59 3.75001 16.59 5.42001 17.72L10.88 20.8701C11.47 21.2101 12.54 21.2101 13.13 20.8701L18.63 17.6901C20.25 16.59 20.25 16.59 20.25 14.88V9.11005C20.25 7.40005 20.25 7.40005 18.58 6.27005L13.12 3.12005C12.83 2.95005 12.42 2.87005 12 2.87005Z'
        fill='#596780'
      />
      <path
        d='M12 15.75C9.93 15.75 8.25 14.07 8.25 12C8.25 9.93 9.93 8.25 12 8.25C14.07 8.25 15.75 9.93 15.75 12C15.75 14.07 14.07 15.75 12 15.75ZM12 9.75C10.76 9.75 9.75 10.76 9.75 12C9.75 13.24 10.76 14.25 12 14.25C13.24 14.25 14.25 13.24 14.25 12C14.25 10.76 13.24 9.75 12 9.75Z'
        fill='#596780'
      />
    </svg>
  );
};

export const AdminsIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      className={className}
      fill='none'
    >
      <path
        d='M11.9999 22.76C10.9099 22.76 9.82992 22.4399 8.97992 21.8099L4.6799 18.5999C3.5399 17.7499 2.6499 15.9799 2.6499 14.5599V7.12995C2.6499 5.58995 3.77992 3.94995 5.22992 3.40995L10.2199 1.53995C11.2099 1.16995 12.7699 1.16995 13.7599 1.53995L18.7599 3.40995C20.2099 3.94995 21.3399 5.58995 21.3399 7.12995V14.5599C21.3399 15.9799 20.4499 17.7499 19.3099 18.5999L15.0099 21.8099C14.1699 22.4399 13.0899 22.76 11.9999 22.76ZM10.7499 2.93995L5.75992 4.80994C4.89992 5.12994 4.1499 6.20995 4.1499 7.12995V14.5599C4.1499 15.5099 4.81992 16.8399 5.56992 17.3999L9.8699 20.6099C11.0199 21.4699 12.9699 21.4699 14.1199 20.6099L18.4199 17.3999C19.1799 16.8299 19.8399 15.4999 19.8399 14.5599V7.12995C19.8399 6.21995 19.0899 5.13994 18.2299 4.80994L13.2399 2.93995C12.5799 2.68995 11.4199 2.68995 10.7499 2.93995Z'
        fill='#596780'
      />
      <path
        d='M11.9999 11.6702C11.9799 11.6702 11.9599 11.6702 11.9299 11.6702C10.4799 11.6302 9.41992 10.5202 9.41992 9.17017C9.41992 7.79017 10.5499 6.66016 11.9299 6.66016C13.3099 6.66016 14.4399 7.79017 14.4399 9.17017C14.4299 10.5302 13.3699 11.6301 12.0199 11.6801C12.0099 11.6701 12.0099 11.6702 11.9999 11.6702ZM11.9299 8.16016C11.3699 8.16016 10.9199 8.61017 10.9199 9.17017C10.9199 9.72017 11.3499 10.1601 11.8899 10.1801C11.8899 10.1801 11.9399 10.1801 11.9999 10.1801C12.5299 10.1501 12.9399 9.71017 12.9399 9.17017C12.9499 8.61017 12.4899 8.16016 11.9299 8.16016Z'
        fill='#596780'
      />
      <path
        d='M11.9998 17.35C11.1398 17.35 10.2698 17.12 9.59982 16.67C8.92982 16.23 8.5498 15.58 8.5498 14.89C8.5498 14.2 8.92982 13.55 9.59982 13.1C10.9498 12.2 13.0598 12.21 14.3998 13.1C15.0698 13.54 15.4498 14.19 15.4498 14.88C15.4498 15.57 15.0698 16.22 14.3998 16.67C13.7298 17.12 12.8598 17.35 11.9998 17.35ZM10.4298 14.34C10.1798 14.5 10.0398 14.7 10.0498 14.88C10.0498 15.06 10.1898 15.26 10.4298 15.42C11.2698 15.98 12.7298 15.98 13.5698 15.42C13.8198 15.26 13.9598 15.06 13.9598 14.88C13.9598 14.7 13.8198 14.5 13.5798 14.34C12.7398 13.79 11.2698 13.79 10.4298 14.34Z'
        fill='#596780'
      />
    </svg>
  );
};
export const LogoutIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M15.24 22.27H15.11C10.67 22.27 8.53002 20.52 8.16002 16.6C8.12002 16.19 8.42002 15.82 8.84002 15.78C9.24002 15.74 9.62002 16.05 9.66002 16.46C9.95002 19.6 11.43 20.77 15.12 20.77H15.25C19.32 20.77 20.76 19.33 20.76 15.26V8.74001C20.76 4.67001 19.32 3.23001 15.25 3.23001H15.12C11.41 3.23001 9.93002 4.42001 9.66002 7.62001C9.61002 8.03001 9.26002 8.34001 8.84002 8.30001C8.42002 8.27001 8.12001 7.90001 8.15001 7.49001C8.49001 3.51001 10.64 1.73001 15.11 1.73001H15.24C20.15 1.73001 22.25 3.83001 22.25 8.74001V15.26C22.25 20.17 20.15 22.27 15.24 22.27Z'
        fill='#596780'
      />
      <path
        d='M15.0001 12.75H3.62012C3.21012 12.75 2.87012 12.41 2.87012 12C2.87012 11.59 3.21012 11.25 3.62012 11.25H15.0001C15.4101 11.25 15.7501 11.59 15.7501 12C15.7501 12.41 15.4101 12.75 15.0001 12.75Z'
        fill='#596780'
      />
      <path
        d='M5.84994 16.1C5.65994 16.1 5.46994 16.03 5.31994 15.88L1.96994 12.53C1.67994 12.24 1.67994 11.76 1.96994 11.47L5.31994 8.12C5.60994 7.83 6.08994 7.83 6.37994 8.12C6.66994 8.41 6.66994 8.89 6.37994 9.18L3.55994 12L6.37994 14.82C6.66994 15.11 6.66994 15.59 6.37994 15.88C6.23994 16.03 6.03994 16.1 5.84994 16.1Z'
        fill='#596780'
      />
    </svg>
  );
};
export const AchievementIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M12.15 16.5V18.6'
        stroke='#596780'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.15002 22H17.15V21C17.15 19.9 16.25 19 15.15 19H9.15002C8.05002 19 7.15002 19.9 7.15002 21V22V22Z'
        stroke='#596780'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M6.15002 22H18.15'
        stroke='#596780'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 16C8.13 16 5 12.87 5 9V6C5 3.79 6.79 2 9 2H15C17.21 2 19 3.79 19 6V9C19 12.87 15.87 16 12 16Z'
        stroke='#596780'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.46998 11.65C4.71998 11.41 4.05998 10.97 3.53998 10.45C2.63998 9.44998 2.03998 8.24998 2.03998 6.84998C2.03998 5.44998 3.13998 4.34998 4.53998 4.34998H5.18998C4.98998 4.80998 4.88998 5.31998 4.88998 5.84998V8.84998C4.88998 9.84998 5.09998 10.79 5.46998 11.65Z'
        stroke='#596780'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.53 11.65C19.28 11.41 19.94 10.97 20.46 10.45C21.36 9.44998 21.96 8.24998 21.96 6.84998C21.96 5.44998 20.86 4.34998 19.46 4.34998H18.81C19.01 4.80998 19.11 5.31998 19.11 5.84998V8.84998C19.11 9.84998 18.9 10.79 18.53 11.65Z'
        stroke='#596780'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const FeedbackIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M22 10V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19H8C4 19 2 18 2 13V8C2 4 4 2 8 2H14'
        stroke='#596780'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5 7C20.8807 7 22 5.88071 22 4.5C22 3.11929 20.8807 2 19.5 2C18.1193 2 17 3.11929 17 4.5C17 5.88071 18.1193 7 19.5 7Z'
        stroke='#596780'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.9965 11H16.0054'
        stroke='#596780'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9955 11H12.0045'
        stroke='#596780'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.99451 11H8.00349'
        stroke='#596780'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const BroadcastIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M14.13 11.3401C14.52 9.70008 13.09 8.27003 11.45 8.66003C10.68 8.85003 10.05 9.48005 9.85998 10.2501C9.46998 11.8901 10.9 13.32 12.54 12.93C13.32 12.74 13.95 12.1101 14.13 11.3401Z'
        stroke='#596780'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.0099 17.19C19.7199 15.59 20.7899 13.32 20.7899 10.79C20.7899 5.92998 16.85 2 12 2C7.14995 2 3.20996 5.93998 3.20996 10.79C3.20996 13.33 4.28996 15.62 6.01996 17.22'
        stroke='#596780'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.00002 14.55C7.08002 13.57 6.51001 12.25 6.51001 10.79C6.51001 7.76004 8.97002 5.30005 12 5.30005C15.03 5.30005 17.49 7.76004 17.49 10.79C17.49 12.25 16.92 13.56 16 14.55'
        stroke='#596780'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.3 16.66L8.85998 18.4501C7.71998 19.8801 8.72997 21.99 10.56 21.99H13.43C15.26 21.99 16.28 19.8701 15.13 18.4501L13.69 16.66C12.83 15.57 11.17 15.57 10.3 16.66Z'
        stroke='#596780'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
