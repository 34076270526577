// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__DReun {
	z-index: 100;

	/* opacity: 0.4; */
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 20px;
}

.style_container__DReun>span {
	color: #000;
}

.style_loader__fhtiB {
	border: 3px solid #fff;
	border-top: 3px solid #1D4ED8;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	animation: style_spin__W5292 2s linear infinite;
}

@keyframes style_spin__W5292 {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}`, "",{"version":3,"sources":["webpack://./src/common/LoadingIndicator/style.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;;CAEZ,kBAAkB;CAClB,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,sBAAsB;CACtB,SAAS;AACV;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,sBAAsB;CACtB,6BAA6B;CAC7B,kBAAkB;CAClB,WAAW;CACX,YAAY;CACZ,+CAAkC;AACnC;;AAEA;CACC;EACC,uBAAuB;CACxB;;CAEA;EACC,yBAAyB;CAC1B;AACD","sourcesContent":[".container {\n\tz-index: 100;\n\n\t/* opacity: 0.4; */\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tflex-direction: column;\n\tgap: 20px;\n}\n\n.container>span {\n\tcolor: #000;\n}\n\n.loader {\n\tborder: 3px solid #fff;\n\tborder-top: 3px solid #1D4ED8;\n\tborder-radius: 50%;\n\twidth: 30px;\n\theight: 30px;\n\tanimation: spin 2s linear infinite;\n}\n\n@keyframes spin {\n\t0% {\n\t\ttransform: rotate(0deg);\n\t}\n\n\t100% {\n\t\ttransform: rotate(360deg);\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__DReun`,
	"loader": `style_loader__fhtiB`,
	"spin": `style_spin__W5292`
};
export default ___CSS_LOADER_EXPORT___;
